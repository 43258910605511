<template>
  <div id="about">
    <b-container>
      <h2>KASA in Zahlen</h2>
      <b-row
        class="mt-4"
      >
        <b-col
          md="3"
          class="number-block"
        >
          <icon
            class="number-block-icon"
            width=""
            height=""
            name="chalkboard-teacher"
          />
          <p class="number-description">
            <b>
              <countTo
                ref="count10"
                v-call-on-scroll-vertical="startCountingFnc('count10')"
                :end-val="partners['mso'] + partners['kirche'] + partners['moschee']"
                :autoplay="false"
                :duration="3000"
              />
              Kurse
            </b> in <br>
            <b>
              <countTo
                ref="count11"
                v-call-on-scroll-vertical="startCountingFnc('count11')"
                :end-val="8"
                :autoplay="false"
                :duration="3200"
              />
              Bundesländern
            </b>
          </p>
        </b-col>
        <b-col
          md="3"
          class="number-block"
        >
          <icon
            class="number-block-icon"
            width=""
            height=""
            name="handshake"
          />
          <p class="number-description">
            <b>
              <countTo
                ref="count20"
                v-call-on-scroll-vertical="startCountingFnc('count20')"
                :end-val="partners['mso'] + partners['kirche'] + partners['moschee']"
                :autoplay="false"
                :duration="3000"
              />Partner
            </b><br>
            <countTo
              ref="count21"
              v-call-on-scroll-vertical="startCountingFnc('count21')"
              :end-val="partners['mso']"
              :autoplay="false"
              :duration="3000"
            />MSO<br>
            <countTo
              ref="count22"
              v-call-on-scroll-vertical="startCountingFnc('count22')"
              :end-val="partners['kirche']"
              :autoplay="false"
              :duration="3000"
            />Kirchen<br>
            <countTo
              ref="count23"
              v-call-on-scroll-vertical="startCountingFnc('count23')"
              :end-val="partners['moschee']"
              :autoplay="false"
              :duration="3000"
            />Moscheen
          </p>
        </b-col>
        <b-col
          md="3"
          class="number-block"
        >
          <icon
            class="number-block-icon"
            width=""
            height=""
            name="users"
          />
          <p class="number-description">
            <b>
              <countTo
                ref="count30"
                v-call-on-scroll-vertical="startCountingFnc('count30')"
                :end-val="1051"
                :autoplay="false"
                :duration="4000"
              />erreichte Teilnehmende
            </b><br>
            <countTo
              ref="count31"
              v-call-on-scroll-vertical="startCountingFnc('count31')"
              :end-val="755"
              :autoplay="false"
              :duration="3000"
            />Frauen<br>
            <countTo
              ref="count32"
              v-call-on-scroll-vertical="startCountingFnc('count32')"
              :end-val="296"
              :autoplay="false"
              :duration="3000"
            />Männer
          </p>
        </b-col>
        <b-col
          md="3"
          class="number-block"
        >
          <icon
            class="number-block-icon"
            width=""
            height="auto"
            name="comments"
          />
          <p class="number-description">
            <b>
              <countTo
                ref="count40"
                v-call-on-scroll-vertical="startCountingFnc('count40')"
                :end-val="277"
                :autoplay="false"
                :duration="3000"
              />Vernetzungstreffen
            </b>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import countTo from 'vue-count-to'

  import client from '../api'
  import store from '../store'

  export default {
    name: 'About',
    components: {
      countTo
    },
    data () {
      return {
        content: [],
        partners: {
          'mso': 0,
          'kirche': 0,
          'moschee': 0
        }
      }
    },
    mounted() {
      this.getNumberFromPlaces('mso')
      this.getNumberFromPlaces('kirche')
      this.getNumberFromPlaces('moschee')
    },
    methods: {
      'startCountingFnc': function (elem) {
        return () => {
          this.$refs[elem].start()
        }
      },
      'getNumberFromPlaces': function (type) {
        const reqestObject = {
          'fields': 'id',
          'meta': 'result_count',
          'filter[type][eq]': type
        }

        if (store.state.showDrafts === false) {
          reqestObject['filter[status][eq]'] = 'published'
        }

        client.getItems('kasa_places', reqestObject).then(apiResponse => {
          this.partners[type] = apiResponse.meta.result_count
        })
      }
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .number-block {
    height: 200px;
    position: relative;
    margin-bottom: 30px;
  }

  .number-block-icon {
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: $gray-300;
    margin-left: 15%;
  }

  .number-description {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    font-size: 150%;
    text-align: center;
    width: 100%;
    display: table-cell;
    padding: 0 0 0 0;
  }


</style>
