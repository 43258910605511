<template>
  <div
    v-if="content.length > 0"
    id="publications"
    class="clearfix"
  >
    <b-container v-if="content.length > 0">
      <h2>Publikationen</h2>
    </b-container>
    <div
      v-set-class-on-scroll-vertical="'bounceInRight'"
      class="invisible animated"
    >
      <b-container
        fluid
        class="news-container"
      >
        <a
          v-for="row in content"
          :key="row.id"
          :href="row.link"
        >
          <template v-if="row.cover_image">
            <b-card
              class="news-card m-3"
              overlay
              :img-src="row.cover_image.data.full_url"
              :img-alt="row.cover_image.title"
              text-variant="black"
              :title="row.title"
              :sub-title="row.date"
            >
              <p class="card-text">
                <vue-markdown
                  quotes="«»„“"
                  :source="row.text"
                />
              </p>

              <div class="card-custom-footer">
                <p>
                  <a :href="row.link">
                    {{ row.link_title }}
                  </a>
                </p>
              </div>
            </b-card>
          </template>
          <template v-else>
            <b-card
              class="news-card m-3"
              text-variant="black"
              :title="row.title"
              :sub-title="row.date"
            >
              <p class="card-text">
                <vue-markdown
                  quotes="«»„“"
                  :source="row.text"
                />
              </p>

              <div class="card-custom-footer">
                <p>
                  <a :href="row.link">
                    {{ row.link_title }}
                  </a>
                </p>
              </div>
            </b-card>
          </template>
        </a>
      </b-container>
    </div>
  </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  import client from '../api'
  import store from '../store'

  export default {
    name: 'Publications',
    components: {
      'vue-markdown': VueMarkdown
    },
    data () {
      return {
        content: []
      }
    },
    mounted() {
      const requestObject = {
        'fields': 'id,title,text,date,cover_image.data.full_url,cover_image.title,link,link_title',
        'sort': '-date'
      }

      if (store.state.showDrafts === false) {
        requestObject['filter[status][eq]'] = 'published'
      }

      client.getItems('kasa_publications', requestObject).then(apiResponse => {
        this.content = apiResponse.data
      })
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .news-card {
    width: 350px;
    height: 400px;

    overflow: hidden;
    display: inline-block;

    .card-body {
      background-color: rgba(255, 255, 255, 0.8);
      white-space: normal;
    }

    .card-custom-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 25px;
      background-color: rgba(255, 255, 255, 0.9);
      border-top: 1px solid $black;
      text-align: center;
    }

    img {
      height: 100%;
      width: 100%;
      padding: 10%;
      object-fit: contain;
      position: relative;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.85);
      transition: opacity 0.4s ease-in-out;

      animation-name: fade-out;

      &:hover, &:focus {
        opacity: 0;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in;
        transform: scale(1.3);
      }
    }
  }

  .news-container {
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;

      > a {
        color: $yiq-text-dark;
      }
  }
</style>
