<template>
  <div id="map">
    <b-container
      fluid
      class="p-0 leaflet-container"
    >
      <l-map
        ref="map"
        class="leaflet-map"
        :zoom="6"
        :bounds="mapBounds"
        :options="{zoomControl: false, scrollWheelZoom: false, touchZoom: true, dragging: false}"
      >
        <l-tile-layer
          url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png"
          attribution="Map tiles by Stamen Design, CC BY 3.0 &mdash; Map data &copy; OpenStreetMap"
        />
        <l-control-zoom position="bottomleft" />
        <l-geo-json :geojson="germany" />
        <v-marker-cluster>
          <l-marker
            v-for="entry in content"
            :key="entry.id"
            :icon="mapIcon"
            :lat-lng="[entry.coordinates.lat, entry.coordinates.lng]"
            @click="openPlaceDetail(entry)"
          />
        </v-marker-cluster>
      </l-map>
    </b-container>

    <b-modal
      ref="placeModal"
      hide-footer
      :title="currentModalContent.title"
      @hide="hiddenPlaceModal"
    >
      <img
        v-if="currentModalContent.image !== null"
        class="modal-image"
        :src="currentModalContent.image.data.full_url"
      >

      <p class="text-justify">
        <vue-markdown
          quotes="«»„“"
          :source="currentModalContent.text"
        />
      </p>
    </b-modal>
  </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown'
  import {LMap, LTileLayer, LMarker, LControlZoom, LGeoJson} from 'vue2-leaflet'
  import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'


  import client from '../api'
  import store from '../store'
  import germany from './map-country-germany'

  export default {
    name: 'Map',
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LControlZoom,
      LGeoJson,
      'v-marker-cluster': Vue2LeafletMarkerCluster,
      'vue-markdown': VueMarkdown
    },
    data () {
      return {
        content: [],
        germany: germany,
        currentModalContent: {
          'title': 'NONE',
          'image': null,
          'text': 'NONE'
        },
        mapIcon: L.icon({ // eslint-disable-line no-undef
          iconUrl: require('../assets/images/marker-icon.png'),
          iconSize: [25, 41],
          shadowUrl: require('../assets/images/marker-shadow.png'),
          shadowSize: [41, 41],
          shadowAnchor: [13, 21],
        }),
        mapBounds: [[54.91451, 4.92188], [47.57653, 7.66846], [52.66972, 14.63379]]
      }
    },
    mounted() {
      const reqestObject = {
        'fields': 'id,title,text,image.data.full_url,image.title,coordinates',
      }

      if (store.state.showDrafts === false) {
        reqestObject['filter[status][eq]'] = 'published'
      }

      client.getItems('kasa_places', reqestObject).then(apiResponse => {
        this.content = apiResponse.data
      })
    },
    methods: {
      openPlaceDetail: function (entry) {
        console.log(entry)
        this.currentModalContent = entry
        this.$refs.placeModal.show()
      },
      hiddenPlaceModal: function () {
        this.mapBounds = [[54.91451, 4.92188], [47.57653, 7.66846], [52.66972, 14.63379]]
      }
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';
  @import '~leaflet/dist/leaflet.css';
  @import "~leaflet.markercluster/dist/MarkerCluster.css";

  .leaflet-container {
    z-index: 0;
    position: relative;
  }

  .leaflet-map {
    height: 100vh;
  }

  .modal-image {
    width: 100%;
  }
</style>

<style lang="scss">
  @import '../style/marker-cluster';
</style>
