<template>
  <div id="coordinators">
    <b-container
      fluid
      class="p-0 pt-5 pb-5 coordinator-background"
    >
      <b-container>
        <h2 class="text-white mt-4">
          Unsere Koordinator*innen
        </h2>
        <b-row
          class="mt-4"
        >
          <b-col
            v-for="row in content"
            :key="row.id"
            md="3"
            class="coordinator-container p-0"
            @click="setClickHighlight(row.id)"
          >
            <div :class="{'click-show': clickHighlight === row.id}">
              <img
                v-if="row.image"
                v-set-class-on-scroll-vertical="'flipInX'"
                class="coordinator-image animated invisible"
                :alt="row.name"
                :src="row.image.data.thumbnails[2].url"
              >
              <img
                v-else
                v-set-class-on-scroll-vertical="'flipInX'"
                class="coordinator-image animated invisible"
                :alt="row.name"
                src="../assets/images/avatar.png"
              >
              <div class="coordinator-info">
                <h5>{{ row.name }}</h5>
                <p class="text-justify">
                  <vue-markdown
                    quotes="«»„“"
                    :source="row.text"
                  />
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  import client from '../api';
  import store from '../store'

  export default {
    name: 'Coordinators',
    components: {
      'vue-markdown': VueMarkdown
    },
    data () {
      return {
        content: [],
        clickHighlight: null
      }
    },
    mounted() {
      const requestObject = {
        'fields': 'id,name,text,image.data.full_url,image.title',
        'filter[section][contains]': this.section
      }

      if (store.state.showDrafts === false) {
        requestObject['filter[status][eq]'] = 'published'
      }

      client.getItems('kasa_coordinators', requestObject).then(apiResponse => {
        this.content = apiResponse.data
      })
    },
    methods: {
      setClickHighlight: function (value) {
        if (this.clickHighlight === value) {
          this.clickHighlight = null
        } else {
          this.clickHighlight = value
        }
      }
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .coordinator-image {
    position: absolute;
    top: 7.5%;
    left: 7.5%;
    bottom: 0;
    right: 0;
    object-fit: cover;
    width: 85%;
    height: 85%;
    border-radius: 100%;
    filter: grayscale(100%);

    transition: all ease-in-out 1s;
  }

  .coordinator-background {
    background-color: $kasa-red;
    background-image: url('../assets/images/arrow-down-white.svg');
    background-repeat: no-repeat;
    background-position: center top;
  }

  .coordinator-container {
    position: relative;

    &:before{
      content: "";
      display: block;
      padding-top: 100%; 	/* initial ratio of 1:1*/
    }

    &:hover, .click-show {
      .coordinator-image {
        filter: grayscale(0%);

        transition: all ease-in-out .2s;
      }
      .coordinator-info {
        opacity: 1;
        visibility: visible;

        transition: all ease-in-out .2s;
      }
    }

    .coordinator-info {
      visibility: hidden;
      transition: all ease-in-out 1s;
      position: absolute;
      top: 80%;
      left: 0%;
      right: 0%;
      z-index: 200;
      border: 3px solid $primary;
      background-color: $white;
      padding: 10px;
      opacity: 0;
      border-radius: 10px;
      padding-top: 50px;
      min-height: 150px;
      background-image: url("../assets/images/arrow-down-kasa-blue.svg");
      background-repeat: no-repeat;
      background-position: center top;
    }
  }
</style>
