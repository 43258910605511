<template>
  <div id="news">
    <b-container>
      <h2>Aktuelles</h2>
    </b-container>
    <div
      v-set-class-on-scroll-vertical="'bounceInRight'"
      class="invisible animated"
    >
      <b-container
        fluid
        class="news-container"
      >
        <b-card
          v-for="row in content"
          :key="row.id"
          class="news-card m-3"
          overlay
          :img-src="row.cover_image.data.thumbnails[0].url"
          :img-alt="row.cover_image.title"
          text-variant="black"
          :title="row.title"
          :sub-title="row.date"
        >
          <p class="card-text">
            <vue-markdown
              quotes="«»„“"
              :source="row.text"
            />
          </p>

          <div class="news-footer">
            <p>
              <router-link :to="'/news/' + row.id">
                Weiterlesen
              </router-link>
            </p>
          </div>
        </b-card>
      </b-container>
    </div>
  </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  import client from '../api'
  import store from '../store'

  export default {
    name: 'News',
    components: {
      'vue-markdown': VueMarkdown
    },
    data () {
      return {
        content: []
      }
    },
    mounted() {
      const requestObject = {
        'fields': 'id,title,text,date,cover_image.data.full_url,cover_image.title',
        'limit': 100,
        'sort': '-date'
      }

      if (store.state.showDrafts === false) {
        requestObject['filter[status][eq]'] = 'published'
      }

      client.getItems('kasa_news', requestObject).then(apiResponse => {
        this.content = apiResponse.data
      })
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .news-card, .card-body {
    width: 350px;
    height: 400px;
  }

  .news-card {

    overflow: hidden;
    display: inline-block;

    .card-body {
      background-color: rgba(255, 255, 255, 0.8);
      white-space: normal;
    }

    .news-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 25px;
      background-color: rgba(255, 255, 255, 0.9);
      border-top: 1px solid $black;
      text-align: center;
    }

    img {
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }

  .news-container {
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
  }
</style>
