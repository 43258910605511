<template>
  <div>
    <div
      id="landing-container"
      class="container-fluid"
      :style="{ backgroundImage: `url('${require('../assets/images/landing-background.jpg')}')` }"
    >
      <div
        id="landing"
        class="container container-onepage"
      >
        <div class="row">
          <div class="col-md-12 col">
            <div class="row-aligned-content">
              <img
                src="../assets/images/kasa_claim.svg"
                width="60%"
                :class="{'animated': true, 'invisible': !isReady, 'fadeIn': isReady}"
              >
              <p class="text-large mt-3">
                <b class="text-primary">
                  Kontrastive Alphabetisierung im Situationsansatz
                </b><br>
                42 Kurse in 8 Bundesländern
              </p>

              <p class="text-muted mt-4">
                Ein Projekt der <a href="https://giz.berlin">
                  GIZ
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>

    </div>

    <about
      title="Über das Projekt Kasa"
      section="about"
    />
    <news />
    <who />
    <mapc />
    <project-numbers />
    <coordinators />
    <material
      title="Arbeitsmittel"
      section="work"
    />
    <material
      title="Partnerprojekte"
      section="partners"
    />
    <press />
    <publications />
  </div>
</template>

<script>
  import About from '../components/About.vue'
  import Material from '../components/Material.vue'
  import News from '../components/News.vue'
  import Map from '../components/Map.vue'
  import Who from '../components/Who.vue'
  import Coordinators from "../components/Coordinators"
  import ProjectNumbers from "../components/ProjectNumbers"
  import Press from "../components/Press"
  import Publications from "../components/Publications"

  export default {
    name: 'Home',
    components: {
      'about': About,
      'material': Material,
      'news': News,
      'mapc': Map,
      'coordinators': Coordinators,
      'who': Who,
      'project-numbers': ProjectNumbers,
      'press': Press,
      'publications': Publications
    },
    data () {
      return {
          isReady: false
      }
    },
    mounted() {
      this.isReady = true
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .container-onepage {
    height: calc(100vh - 55px);
    display: table;
  }

  .container-onepage .row {
    display: table-row;

    .col {
      display: table-cell;
      vertical-align: middle;
      min-height: 0;
      .row-aligned-content {

        text-align: center;

        img {
          margin-bottom: 0;
          max-width: 80%;
        }
      }
    }
  }

  #landing {
    background-color: rgba(255, 255, 255, 0.8);
  }

  #landing-container {
    background-size: cover;
    background-attachment: fixed;
    margin-bottom: 25px;
  }

  .text-large {
    font-size: 170%;
  }
</style>
