<template>
  <div id="who">
    <b-container>
      <h2>Wer wir sind</h2>
      <b-row
        v-for="row in content"
        :key="row.id"
        class="mt-4"
      >
        <b-col
          md="3"
          class="who-image-row"
        >
          <div class="who-image-row-pic">
            <img
              v-set-class-on-scroll-vertical="'fadeIn'"
              class="who-image invisible animated"
              :alt="row.image.title"
              :src="row.image.data.thumbnails[2].url"
            >
          </div>
        </b-col>
        <b-col md="9">
          <div
            v-set-class-on-scroll-vertical="'fadeIn'"
            class="invisible animated"
          >
            <h4>{{ row.subtext }}</h4>
            <h3>{{ row.name }}</h3>

            <blockquote>
              <p class="mb-0">
                {{ row.quote }}
              </p>
            </blockquote>

            <p class="text-justify">
              {{ row.description }}
            </p>

            <p class="text-muted text-right contact-info">
              <icon
                name="envelope"
                scale="0.9"
              /> {{ row.mail }} | <icon
                name="phone"
                scale="0.9"
              />  {{ row.telephone }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import client from '../api';
  import store from '../store'

  export default {
    name: 'Who',
    data () {
      return {
        content: []
      }
    },
    mounted() {
      const reqestObject = {
        'fields': 'name,quote,image.data.full_url,image.title,description,telephone,mail,subtext',
        'sort': 'sort'
      }

      if (store.state.showDrafts === false) {
        reqestObject['filter[status][eq]'] = 'published'
      }

      client.getItems('kasa_people', reqestObject).then(apiResponse => {
        this.content = apiResponse.data
      })
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  blockquote {
    border-left: 5px solid $primary;
    padding: 10px 20px;
  }

  .who-image-row-pic {
    position: relative;
    vertical-align: top;

    &:before {
      content: "";
      display: block;
      padding-top: 100%; 	/* initial ratio of 1:1*/
    }

    img {
      position: absolute;
      top: 7.5%;
      left: 7.5%;
      bottom: 7.5%;
      right: 0;
      object-fit: cover;
      width: 85%;
      height: 85%;
      border-radius: 100%;
    }
  }

  .contact-info {
    font-size: 85%;

    .fa-icon {
      margin-top: -2px;
    }
  }
</style>
