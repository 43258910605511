<template>
  <div>
    <b-container :id="section">
      <h2>{{ title }}</h2>
      <b-row
        v-for="row in leftRightToggledContent"
        :key="row.id"
        class="mt-4"
      >
        <b-col
          v-if="row.display_side === 'left'"
          md="4"
          class="p-0"
        >
          <img
            v-set-class-on-scroll-vertical="'decorator-image-zoom'"
            class="decorator-image-invisible decorator-image"
            :alt="row.image.title"
            :src="row.image.data.full_url"
          >
          <img
            v-set-class-on-scroll-vertical="'decorator-image-zoom'"
            class="decorator-image decorator-image-visible"
            :alt="row.image.title"
            :src="row.image.data.full_url"
          >
        </b-col>
        <b-col md="8">
          <h3>{{ row.title }}</h3>

          <p class="text-justify">
            <vue-markdown
              quotes="«»„“"
              :source="row.text"
            />
          </p>
        </b-col>
        <b-col
          v-if="row.display_side === 'right'"
          md="4"
        >
          <img
            v-set-class-on-scroll-vertical="'decorator-image-zoom'"
            class="decorator-image-invisible decorator-image"
            :alt="row.image.title"
            :src="row.image.data.full_url"
          >
          <img
            v-set-class-on-scroll-vertical="'decorator-image-zoom'"
            class="decorator-image decorator-image-visible"
            :alt="row.image.title"
            :src="row.image.data.full_url"
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  import client from '../api';

  export default {
    name: 'Material',
    components: {
      'vue-markdown': VueMarkdown
    },
    props: {
      title: {
        type: String,
        required: true
      },
      section: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        content: []
      }
    },
    computed: {
      leftRightToggledContent: function () {
        const result = []
        let state = 'left'
        for (let idx = 0; idx < this.content.length; idx++) {
          const row = this.content[idx]
          row.display_side = state
          result.push(row)
          state = state === 'left' ? 'right' :  'left'
        }
        return result
      }
    },
    mounted() {
      client.getItems('kasa_about', {
        'fields': 'title,text,image.data.full_url,image.title',
        'filter[section][contains]': this.section
      }).then(apiResponse => {
        this.content = apiResponse.data
      })
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  .decorator-image {
    width: 35%;
    transition: width ease .3s;
  }

  .decorator-image-visible {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .decorator-image-invisible {
    opacity: 0;
    pointer-events: none;
  }

  .decorator-image-zoom {
    width: 55%;
  }
</style>
